<template>
    <div id="footer">
        <div class="container">
            <div style="font-size:0.7rem;">Dies ist ein privates Studentenprojekt der IU Internationalen&nbsp;Hochschule</div>
            <img src="/iu-logo.svg" width="166" height="88" alt="Logo IU" />
            <p style="font-size:0.7rem;"><b-icon-github style="margin:0 0 4px; color:#888; font-size:1rem;"></b-icon-github> <a href="https://github.com/heinzaa/IU-Korrekturmanagementsystem" target="_blank">Github Project</a></p>
        </div>
    </div>
</template>

<script>
</script>

<style>
#footer {
    font-size: 0.7rem;
}
#footer p a {
    text-decoration: none;
    color: #888;
}
#footer p a:hover {
    text-decoration: underline;
}
</style>